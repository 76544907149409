<h1 class="subscription-headline" id="title">{{__('subscriptions')}}</h1>
<div class="subscription-container">
<div class="text-container">
  <div class="headline">
    <div class="headline-left">
    <img class="icon-svg" src="assets/icons/chatbot/JayIcon.svg" alt="chatbot icon"/>
      <h3 class="headline-title">{{__('personalChatbot')}}</h3>
    </div>
    <div class="headline-right">
    <div id="subscriptionInformation" class="subscription-information {{subscriptionInformationClass}}">
      <span class="subscription-information-status">{{subscriptionStatus}}</span>
      <span class="subscription-information-grey ">{{subscriptionInformationText}}</span>
    </div>
    <button *ngIf="!isTerminationPossible" class="id37-btn id37-btn-primary id37-btn-rounded-1 buy-button" (click)="buyChatbotSubscription()">{{__('personalChatbotBuyButton')}}</button>
    <button  *ngIf="isTerminationPossible" class="id37-btn id37-btn-primary id37-btn-rounded-1 buy-button" (click)="openCancelChatbotModal()">{{__('personalChatbotCancelSubscriptionButton')}}</button>
    </div>
  </div>

  <span class="text">
    {{__('personalChatbotText1')}}<br>
     </span>

  @if(!isInformationExpanded) {
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="1" viewBox="0 0 834 1" fill="none">
      <path d="M0.5 0.5H833.5" stroke="#DCDCDD" stroke-dasharray="4 4"/>
    </svg>
    <button class="informationButton" (click)="toggleExpandInformation()" >{{__('personalChatbotButtonClosed')}}</button>
  } @else {
    <span class="text">
  {{__('personalChatbotText2')}}<br>
      {{__('personalChatbotText3')}}
  </span>
    <span class="text">
    <b>{{__('personalChatbotHeadline')}}<br><br></b>
    <img class="orange-arrow" src="assets/icons/arrow_orange_circle.svg" alt="orange arrow">{{__('personalChatbotFeature1')}}<br>
    <img class="orange-arrow" src="assets/icons/arrow_orange_circle.svg" alt="orange arrow">{{__('personalChatbotFeature2')}}<br>
    <img class="orange-arrow" src="assets/icons/arrow_orange_circle.svg" alt="orange arrow">{{__('personalChatbotFeature3')}}<br>
    <img class="orange-arrow" src="assets/icons/arrow_orange_circle.svg" alt="orange arrow">{{__('personalChatbotFeature4')}}<br>
    <img class="orange-arrow" src="assets/icons/arrow_orange_circle.svg" alt="orange arrow">{{__('personalChatbotFeature5')}}<br>
    <img class="orange-arrow" src="assets/icons/arrow_orange_circle.svg" alt="orange arrow">{{__('personalChatbotFeature6')}}<br>
  </span>
    <span class="text">
    <b>{{__('personalChatbotQuitHeadline')}}<br><br></b>
      {{__('personalChatbotQuitText1')}}<br><br>

    <div class="quitText">
    {{__('personalChatbotQuitText2')}}<br><br>
      {{__('personalChatbotQuitText3')}}
       <a href="{{__('link1')}}" target="_blank">{{__('linkText1')}}</a>
      {{__('personalChatbotQuitText4')}}
      <a href="{{__('link2')}}" target="_blank">{{__('linkText2')}}</a>
      </div>
     </span>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="1" viewBox="0 0 834 1" fill="none">
      <path d="M0.5 0.5H833.5" stroke="#DCDCDD" stroke-dasharray="4 4"/>
    </svg>
    <button class="informationButton" (click)="toggleExpandInformation()" >{{__('personalChatbotButtonOpened')}}</button>
  }

</div>

  <id37-modal [(modalOpen)]="isCancelModalVisible">
    <id37-modal-title>
      <img class="icon-svg" src="assets/icons/chatbot/JayIcon.svg" alt="chatbot icon"/>
      {{__('cancelChatbotHeadline')}}
    </id37-modal-title>
    <id37-modal-body>
      <span class="modal-subtitle"> <b>{{__('cancelChatbotTitel')}}</b></span>
      {{__('cancelTextA')}}
      {{statusDate | JsDate}}
      {{__('cancelTextB')}}
    </id37-modal-body>
    <id37-modal-footer [align]="'right'">
      <div class="id37-btn-row">
        <button (click)="closeCancelChatbotModal()" class="id37-btn-outline-secondary"
                type="button">
          {{__('closeModal')}}
        </button>
        <button (click)="cancelChatbotSubscription()" class="id37-btn-danger"
                type="button">
          {{__('cancelChatbotSubscription')}}
        </button>
      </div>
    </id37-modal-footer>
  </id37-modal>
</div>
