<div class="content-container">
  <div class="parent">
    <div style="margin-top: 1em">
      <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
        <title>icon - 404-2</title>
        <desc>Created with Sketch.</desc>
        <g id="icon---404-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect id="Rectangle" stroke="#1A2126" stroke-width="6" x="3" y="3" width="114" height="114"
                rx="57"></rect>
          <path
              d="M60.0001698,6 C93.137161,6 120,32.862839 120,65.9998302 C120,68.7123593 119.819999,71.3828469 119.471291,74 C115.559974,44.6448202 90.4245676,21.9999547 60.0001698,21.9999547 L60.0001698,21.9999547 C29.5754324,21.9999547 4.44002632,44.6448202 0.52873429,73.999429 C0.180000855,71.3828469 3.32189005e-16,68.7123593 0,65.9998302 C-4.05811102e-15,32.862839 26.862839,6 59.9998302,6 L60.0001698,6 Z"
              id="Combined-Shape" fill="#FF5711" opacity="0.100000001"></path>
          <path
              d="M67,82 C65.7152614,79.0355275 63.0596549,77 59.9920763,77 C56.9370559,77 54.2906413,79.0188955 53,81.9636388"
              id="Path" stroke="#1A2126" stroke-width="2" stroke-linecap="round"></path>
          <circle id="Oval" fill="#1A2126" cx="28" cy="68" r="6"></circle>
          <circle id="Oval" fill="#1A2126" cx="92" cy="68" r="6"></circle>
        </g>
      </svg>
    </div>
    <div style="margin-top: 1em">
      <h1>Our Service is temporarily unavailable</h1>
      <h2>Please try again at another point in time</h2>
    </div>
  </div>
</div>
