import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from './admin.component';
import {ClrAlertModule, ClrDatagridModule, ClrIconModule} from '@clr/angular';
import {AdminreportComponent} from './adminreport/adminreport.component';
import {DiscountcodesComponent} from './discountcodes/discountcodes.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DiscountcodeFormComponent} from './discountcodes/discountcode-form/discountcode-form.component';
import {ServiceComponent} from './service/service.component';
import {CollapsibleModule} from '../collapsible/collapsible.module';
import {MetricsComponent} from './metrics/metrics.component';
import {DateTimeModule} from '../datetime/datetime.module';
import {PricingReportComponent} from './pricing-report/pricing-report.component';
import {MessagesModule} from './messages/messages.module';
import {BillingDetailsComponent} from './billing-details/billing-details.component';
import {ModalModule} from '../modal/modal.module';
import {Id37Module} from '../id37/id37.module';
import {StatisticsComponent} from './statistics/statistics.component';
import {FriendshipGraphComponent} from './friendship-graph/friendship-graph.component';
import {
  DiscountCodeUsagesReportComponent
} from './discountcodes/discount-code-usages-report/discount-code-usages-report.component';
import {
  DiscountCodeGenerateFormComponent
} from './discountcodes/discount-code-generate-form/discount-code-generate-form.component';
import {
  PositionUserReportComponent
} from './position-user-report/position-user-report.component';
import {ChatbotDocumentsComponent} from './chatbot-documents/chatbot-documents.component';
import {
  ChatbotDocumentsFormComponent
} from './chatbot-documents/chatbot-documents-form/chatbot-documents-form.component';
import { ChatbotSystemPromptComponent } from './chatbot-system-prompt/chatbot-system-prompt.component';

@NgModule({
  imports: [
    CommonModule,
    ClrIconModule,
    ReactiveFormsModule,
    FormsModule,
    ClrDatagridModule,
    CollapsibleModule,
    DateTimeModule,
    MessagesModule,
    ModalModule,
    Id37Module,
    ClrAlertModule,
    ChatbotSystemPromptComponent
  ],
  declarations: [
    AdminComponent,
    AdminreportComponent,
    DiscountcodesComponent,
    DiscountcodeFormComponent,
    ServiceComponent,
    MetricsComponent,
    PricingReportComponent,
    BillingDetailsComponent,
    StatisticsComponent,
    FriendshipGraphComponent,
    DiscountCodeUsagesReportComponent,
    DiscountCodeGenerateFormComponent,
    PositionUserReportComponent,
    ChatbotDocumentsComponent,
    ChatbotDocumentsFormComponent,
  ]
})
export class AdminModule {
}
