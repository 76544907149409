<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <h1 id="hello">
      {{__('hello')}} {{name}}{{__('afterName')}}
    </h1>

    <!-- GREETING -->
    <div id="greeting" class="id37-text1">
      <div *rolesAllowed="'client'; strict true">
        <div>{{__('generalGreetingClient')}}</div>
      </div>
      <ng-container *rolesAllowed="'master'">
        <div>{{__('generalGreeting')}}</div>
        <div>{{__('additionalMasterGreeting')}}</div>
      </ng-container>
    </div>

    <!-- TEST STATUS -->

    <app-test-overview *ngIf="tests && tests.length > 0" [tests]="tests"></app-test-overview>

    <div class="dashboard-btn-container">

      <div *ngIf="hasReferrerWW" style="width: 100%">
        <app-calendly></app-calendly>
      </div>

      <ng-container *rolesAllowed="undefined; not ['administrator', 'partner']">
        <ng-container *featureAllowed="'payment'">
          <ng-container *ngIf="tests && tests.length === 0">
            <div *ngIf="buyTestLoadingIndicator" class="dashboard-btn-wrapper">
              <button class="dashboard-btn">
                <app-loading-indicator class="icon-svg"></app-loading-indicator>
                <span class="icon-text">{{__('buyTest')}}</span>
              </button>
            </div>
            <div *ngIf="!buyTestLoadingIndicator" class="dashboard-btn-wrapper">
              <button class="dashboard-btn" (click)="buyTest()">
                <img class="icon-svg" src="assets/icons/ID37_TestKaufen_100x100.svg"
                     alt="pictogram of three user side by side"/>
                <span class="icon-text">{{__('buyTest')}}</span>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *rolesAllowed="'master'; not 'administrator'">
        <div class="dashboard-btn-wrapper" id="test">
          <button [routerLink]="['/my-customers']" class="dashboard-btn">
            <img class="icon-svg" src="assets/icons/ID37_Klienten_100x100.svg"
                 alt="pictogram of three user side by side"/>
            <span class="icon-text">{{__('myCustomers')}}</span>
          </button>
        </div>
      </ng-container>

      <!--
      The first video source is for safari, which supports transparency for HEVC encoded .mov files. Every other
      browser should take the .webm.
      -->

      <ng-container *ngIf="hasBot">
        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/chatbot']"
                  class="dashboard-btn">
            <video autoplay loop muted playsinline class="icon-svg" disablePictureInPicture>
              <source src="assets/icons/chatbot/Jay_Animated.mov" type='video/mp4;codecs=hvc1'/>
              <source src="assets/icons/chatbot/Jay_Animated.webm" type="video/webm"/>
            </video>
            <span class="icon-text">{{__('chatbot')}}</span>
          </button>
          <div class="stoerer-icon">
            <div class="stoerer-text">
              <span style="font-size: 1em; font-weight: 500">
                  {{__('new')}}
                </span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *featureAllowed="'friends'">

        <div [routerLink]="['/friends']" class="dashboard-btn-wrapper">
          <button class="dashboard-btn">
            <img class="icon-svg" src="assets/icons/ID37_Netzwerk_100x100.svg"
                 alt="pictogram of three user side by side"/>
            <span class="icon-text">{{__('friends')}}</span>
          </button>
        </div>

        <div [routerLink]="['/teams']" class="dashboard-btn-wrapper">
          <button class="dashboard-btn last-btn">
            <img class="icon-svg" src="assets/icons/ID37_Teamvergleich_100x100.svg" alt="teams"/>
            <span class="icon-text">{{__('teams')}}</span>
          </button>
        </div>
      </ng-container>
      <ng-container *rolesAllowed="'master'; not 'administrator'">
        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/toolbox']" class="dashboard-btn">
            <img class="icon-svg" src="assets/icons/ID37_Toolbox_100x100.svg" alt="wrenches"/>
            <span class="icon-text">{{__('toolbox')}}</span>
          </button>
        </div>
      </ng-container>

      <ng-container *featureAllowed="'friends'">
        <div *featureAllowed="'recommend'" class="dashboard-btn-wrapper">
          <button (click)="openRecommendations()"
                  class="dashboard-btn">
            <img class="icon-svg invite" src="assets/icons/ID37_Empfehlen_100x100.svg"
                 alt="thumbs up"/>
            <span class="icon-text">{{__('suggest.friend')}}</span>
          </button>
          <ng-container *ngIf="userInviteOptionComponent.recommendationFFInfo?.canRecommendFriendsAndFamily &&
                   userInviteOptionComponent.recommendationFFInfo?.friendsAndFamilyRecommendationsLeft > 0">
            <div class="stoerer-icon">
              <div class="stoerer-text">
                {{friendsAndFamilyDiscountInPercentage}}%
                <span style="font-size: 0.7em; font-weight: 300">
                  {{__('suggest.friend.discount')}}
                </span>
              </div>
            </div>
          </ng-container>
        </div>

        <app-user-invite-option #userInviteOptionComponent></app-user-invite-option>
      </ng-container>


      <ng-container *rolesAllowed="'master'; not 'administrator'">
        <div class="dashboard-btn-wrapper">
          <button class="dashboard-btn"
                  (click)="showDocuments();">
            <img class="icon-svg" src="assets/icons/ID37_Dokumente_100x100.svg"
                 alt="stacked documents"/>
            <span class="icon-text">{{__('id37Documents')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button class="dashboard-btn"
                  (click)="showVideos();">
            <img class="icon-svg" src="assets/icons/ID37_Erklaervideos_100x100.svg"
                 alt="round play button"/>
            <span class="icon-text">{{__('id37ExplanatoryVideos')}}</span>
          </button>
        </div>

      </ng-container>

      <ng-container *rolesAllowed="'administrator'">
        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin']"
                  class="dashboard-btn">
            <img class="icon-svg" src="assets/icons/ID37_Klienten_100x100.svg"
                 alt="pictogram of three user side by side"/>
            <span class="icon-text">{{__('allusers')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/report']"
                  class="dashboard-btn">
            <img class="icon-svg" src="assets/icons/ID37_Dokumente_100x100.svg"
                 alt="stacked documents"/>
            <span class="icon-text">Master {{__('statistics')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/pricing-report']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faDollarSign"
                       style="width: 45%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">Pricing report</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/messages']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faEnvelope" style="width: 70%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('messages')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/discountcode']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faReceipt" style="width: 60%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('discountcode')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/discountcode-usages-export']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faReceipt" style="width: 60%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('discountcodeUsagesExport')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/position-user-report']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faReceipt" style="width: 60%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('positionUserReportExport')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/service']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faCogs" style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('service')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/statistics']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faTable"
                       style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('statistics')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/billing-details']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faMoneyBill"
                       style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('billingDetails')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/metrics']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faChartArea"
                       style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('metrics')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/friendship-graph']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faProjectDiagram"
                       style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('friendshipGraph')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/chatbot-documents']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faReceipt"
                       style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('chatbot-documents')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/admin/chatbot-system-prompt']"
                  class="dashboard-btn">
            <div class="icon-svg">
              <fa-icon class="fit-parent" [icon]="faReceipt"
                       style="width: 80%; margin: auto;"></fa-icon>
            </div>
            <span class="icon-text">{{__('chatbot-prompt')}}</span>
          </button>
        </div>


      </ng-container>


      <ng-container>
        <div *rolesAllowed="undefined; not ['master', 'administrator', 'partner']"
             class="dashboard-btn-wrapper">
          <button class="dashboard-btn"
                  (click)="openVimeoLink()">
            <img class="icon-svg" src="assets/icons/ID37_Erklaervideos_100x100.svg"
                 alt="vimeo link for explanation"/>
            <span class="icon-text">{{__('id37ExplanatoryVideos')}}</span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="doesUserComeFromPartnerOrIsPartner()">
        <ng-container *rolesAllowed="['partner']">
          <div class="dashboard-btn-wrapper">
            <button [routerLink]="['/my-customers']"
                    class="dashboard-btn">
              <img class="icon-svg" src="assets/icons/ID37_Klienten_100x100.svg"
                   alt="pictogram of three user side by side"/>
              <span class="icon-text">{{__('myCustomers')}}</span>
            </button>
          </div>
        </ng-container>
        <div class="dashboard-btn-wrapper">
          <button class="dashboard-btn"
                  (click)="openVertellisShop()">
            <app-profile-picture [userId]="getPartnerUserId()"></app-profile-picture>
            <span class="icon-text">{{__('vertellis.shop')}}</span>
          </button>
        </div>
      </ng-container>

    </div>

  </div>

</div>
