import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderComponent} from './order/order.component';
import {OrderCompletedComponent} from './order-completed/order-completed.component';
import {AddressFormComponent} from './address/address-form/address-form.component';
import {UnzerCreditCardFormComponent} from './payment/unzer-credit-card-form/unzer-credit-card-form.component';
import {UnzerPayPalFormComponent} from './payment/unzer-paypal-form/unzer-paypal-form.component';
// eslint-disable-next-line max-len
import {
  PaymentProviderAndMethodChooserComponent
} from './payment/payment-provider-and-method-chooser/payment-provider-and-method-chooser.component';
import {OrdersComponent} from './orders/orders.component';
import {Id37Module} from '../id37/id37.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrderProcessingComponent} from './order-processing/order-processing.component';
import {OrderNotCompletedComponent} from './order-not-completed/order-not-completed.component';
import {CartOverviewComponent} from './cart/cart-overview/cart-overview.component';
import {OrderOverviewComponent} from './order-overview/order-overview.component';
import {PositionsComponent} from './position/positions/positions.component';
import {OngoingPaymentComponent} from './payment/ongoing-payment/ongoing-payment.component';
import {DateTimeModule} from '../datetime/datetime.module';
import {SelectModule} from '../select/select.module';
import {ModalModule} from '../modal/modal.module';
import {OrdersInvoiceComponent} from './orders/orders-invoice/orders-invoice.component';
import {OrdersSubscriptionsComponent} from './orders/orders-subscriptions/orders-subscriptions.component';
import {OrdersTestingComponent} from './orders/orders-testing/orders-testing.component';
import {ClrDatagridModule} from '@clr/angular';
import {DirectivesModule} from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    Id37Module,
    FormsModule,
    ReactiveFormsModule,
    DateTimeModule,
    SelectModule,
    ModalModule,
    DirectivesModule,
    ClrDatagridModule
  ],
  declarations: [
    OrderComponent,
    OrderCompletedComponent,
    AddressFormComponent,
    UnzerCreditCardFormComponent,
    PaymentProviderAndMethodChooserComponent,
    OrdersComponent,
    OrderProcessingComponent,
    OrderNotCompletedComponent,
    CartOverviewComponent,
    OrderOverviewComponent,
    PositionsComponent,
    OngoingPaymentComponent,
    UnzerPayPalFormComponent,
    OrdersInvoiceComponent,
    OrdersSubscriptionsComponent,
    OrdersTestingComponent
  ],
  exports: [
    PositionsComponent
  ]
})
export class OrderModule {
}
