import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChatbotMessagePair, ChatbotMessagePairCreate} from '../state/chatbot-message-pair';
import {ChatbotMessagePairRepresentation} from '../state/chatbot-message-pair-representation';
import {CurrentUser} from '../../user/current-user.service';

const API_URL = environment.CHATBOT_API;

@Injectable({
  providedIn: 'root'
})
export class ChatbotMessagePairService {

  constructor(private http: HttpClient, private currentUser: CurrentUser) {
  }


  addMessagePairStreaming(messagePairCreate: ChatbotMessagePairCreate): Observable<any>{
    return this.http.post(API_URL + '/messagepairs/add/streaming', {
      chat_id: messagePairCreate.chat_id,
      question: messagePairCreate.question,
      timestamp: messagePairCreate.timestamp,
      language: this.currentUser.locale
    }, { responseType: 'text', reportProgress: true, observe: 'events'});
  }


  public addMessagePair(messagePairCreate: ChatbotMessagePairCreate): Observable<ChatbotMessagePair> {
    return this.http.post<ChatbotMessagePair>(
      API_URL + '/messagepairs/add',
      {
        chat_id: messagePairCreate.chat_id,
        question: messagePairCreate.question,
        timestamp: messagePairCreate.timestamp
      }
    ).pipe(map((rep: ChatbotMessagePairRepresentation) => ChatbotMessagePair.buildFrom(rep)));
  }

  public getMessagePairByID(question_id: number): Observable<ChatbotMessagePair> {
    return this.http.get<ChatbotMessagePair>(
      API_URL + '/messagepairs/get/' + question_id,
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(map((chatbotMessagePairRepresentation: ChatbotMessagePairRepresentation) =>
      ChatbotMessagePair.buildFrom(chatbotMessagePairRepresentation)
    ));
  }

  public rateAnswer(question_id: number, rating: number): Observable<ChatbotMessagePair> {
    return this.http.post<ChatbotMessagePair>(
      API_URL + '/messagepairs/rate',
      {
        question_id,
        rating
      }
    ).pipe(map((chatbotMessagePairRepresentation: ChatbotMessagePairRepresentation) =>
      ChatbotMessagePair.buildFrom(chatbotMessagePairRepresentation)
    ));
  }

  public commentAnswer(question_id: number, content: string): Observable<ChatbotMessagePair> {
    return this.http.post<ChatbotMessagePair>(
      API_URL + '/messagepairs/comment',
      {
        question_id,
        content
      }
    ).pipe(map((chatbotMessagePairRepresentation: ChatbotMessagePairRepresentation) =>
      ChatbotMessagePair.buildFrom(chatbotMessagePairRepresentation)
    ));
  }
}
