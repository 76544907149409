<section class="invoices-container">
  <h1 class="invoices-headline">{{ __('invoices.header') }}</h1>

  @if(!isMobileView()) {
    <app-webapp-table
      [tableHeader]="webappTableHeaderColumns"
      [webappTableBodyRows]="webappTableBodyRows"
      [buttonClickedParams]="buttonClickedParams"
      (downloadInvoicesButtonClickedEvent)="downloadInvoicePdf($event)"
    ></app-webapp-table>
  } @else {
    <app-mobile-table
      [orders]="orders"
      [isUnfinished]="isUnfinished"
      (downloadInvoicesButtonClickedEvent)="downloadInvoicePdf($event)"
    ></app-mobile-table>
  }
</section>


