import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UnzerPaymentResourceRepresentation} from '../unzer-payment-resource-representation';
import {environment} from '../../../../environments/environment';
import {TranslatorService} from '../../../translation/translator.service';
import {CurrentUser} from '../../../user/current-user.service';

declare let unzer: any;

const HEIDELPAY_PUBLIC_KEY = environment.HEIDELPAY_PUBLIC_KEY;

@Component({
  selector: 'app-unzer-credit-card-form',
  templateUrl: './unzer-credit-card-form.component.html',
  styleUrls: ['./unzer-credit-card-form.component.scss']
})
export class UnzerCreditCardFormComponent implements OnInit {

  @Output()
    doCharge: EventEmitter<UnzerPaymentResourceRepresentation> = new EventEmitter<UnzerPaymentResourceRepresentation>();

  ongoingSubmission: boolean = false;

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.initHeidelpay();
  }

  initHeidelpay() {
    const heidelpayInstance = new unzer(HEIDELPAY_PUBLIC_KEY, {locale: this.currentUser.locale});
    const card = heidelpayInstance.Card();
    card.create('number', {
      containerId: 'card-element-id-number',
      onlyIframe: false
    });
    card.create('expiry', {
      containerId: 'card-element-id-expiry',
      onlyIframe: false
    });
    card.create('cvc', {
      containerId: 'card-element-id-cvc',
      onlyIframe: false
    });

    // Handling the forms submission
    const form = document.getElementById('payment-form');

    form.addEventListener('submit', event => {
      this.ongoingSubmission = true;
      event.preventDefault();
      const cardPromise = card.createResource();
      Promise.all([cardPromise])
        .then(values => {
          this.doCharge.emit(new UnzerPaymentResourceRepresentation(
            values[0].id as string
          ));
          this.ongoingSubmission = false;
        })
        .catch(error => {
          console.error('Unable to execute heidelpay promises. ', error);
          this.ongoingSubmission = false;
        });
    });
  }

  __(key: string): string {
    return this.translatorService.translate('order.' + key);
  }

}
