import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {ChatbotChat, ChatbotChatCreate} from '../state/chatbot-chat';
import {ChatbotChatService} from '../api-services/chatbot-chat.service';
import {ChatbotSendMessageService} from '../utility-services/chatbot-send-message.service';
import {ChatbotChatCreateService} from '../utility-services/chatbot-chat-create-service';
import {ChatbotChatUser} from '../state/chatbot-chat-user';
import {FormGroup} from '@angular/forms';
import {ChatbotChatCreateAdminFormService} from '../utility-services/chatbot-chat-create-admin-form.service';
import {ChatbotUserService} from '../api-services/chatbot-user.service';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-chatbot-chat-input',
  templateUrl: './chatbot-chat-input.component.html',
  styleUrls: ['./chatbot-chat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotChatInputComponent implements OnInit {

  @Input()
    chatbotChat: ChatbotChat;

  @Output() sendingMessage = new EventEmitter<any>();

  @Output() chatCreated = new EventEmitter<ChatbotChat>();

  @Input() adminCreateFormOpen: boolean = false;

  @Input()
    errorOccurred: boolean;
  @Input()
    errorMessage: string;

  adminCreateForm: FormGroup;
  sendWithEnter: boolean = true;
  message: string = '';
  inputFocused: boolean = false;
  isSending: boolean = false;

  constructor(private translatorService: TranslatorService,
              private sendMessageService: ChatbotSendMessageService,
              private chatbotChatService: ChatbotChatService,
              private chatbotUserService: ChatbotUserService,
              private createChatService: ChatbotChatCreateService,
              private cdr: ChangeDetectorRef,
              private adminFormService: ChatbotChatCreateAdminFormService,
              private currentUser: CurrentUser) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEventOverrideDefaults(event: KeyboardEvent) {
    if (event.key === 'Enter'
      && this.inputFocused
      && this.sendWithEnter
      && !event.shiftKey) {
      // Prevent, that enter adds an additional newline before sending the new message.
      event.preventDefault();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter'
      && this.inputFocused
      && this.sendWithEnter && !event.shiftKey
      && !this.isMessageEmpty()) {
      this.send();
    }
  }


  ngOnInit(): void {
    this.adminCreateForm = this.adminFormService.getAdminForm();
  }

  send(): void {
    // case 1: already existing chatbot-chat
    if (this.chatbotChat) {
      this.sendMessage().catch(error => console.error('Error send Message enter key:', error));
    }
    // case 2: new chat as administrator
    else if (this.currentUser.hasRole('administrator')) {
      this.openForm();
    }
    // case 3: new chat as regular user
    else{
      this.createNewChatAndSendMessage();
    }
  }

  async sendMessage(): Promise<void> {
    if (this.isMessageEmpty() || this.isChatbotActive()){
      return;
    }
    this.sendingMessage.emit();
    // Store message to send and clear input field.
    const messageToSend = this.message;
    this.message = '';
    await this.sendMessageService.sendMessage(messageToSend, this.chatbotChat)
      .catch(error => console.error('Error occurred:', error))
      .finally(() => {
        this.isSending = false;
        this.sendingMessage.emit();
      });
  }

  isMessageEmpty(): boolean {
    return (!this.message || this.message === '');
  }

  isChatbotActive(): boolean {
    return this.chatbotChat && this.chatbotChat.sending;
  }

  openForm(): void {
    this.adminCreateFormOpen = true;
  }

  createNewChatAndSendMessage() {
    this.createChat().then(value => {
      this.chatbotChat = value;
      this.sendMessage().catch(error => console.error('Failed to send message', error));
    }).catch(error => console.error('Failed to create chat', error));
  }

  async newChatAdmin() {
    try {
      await this.createChatAdmin();
    }
    catch (error){
      console.error('Failed to create chat admin', error);
    }
    try {
      await this.sendMessage();
    }
    catch (error){
      console.error('Failed to send message admin', error);
    }
  }

  newChatSample(content: string) {
    this.message = content;
    if (this.currentUser.hasRole('administrator')){
      this.openForm();
      this.cdr.detectChanges();
    }
    else{
      try {
        this.createNewChatAndSendMessage();
      }
      catch (error){
        console.error('Error new sample Chat ', error);
      }
    }
  }

  async createChat(): Promise<ChatbotChat> {
    let newChat: ChatbotChat;

    await this.createChatService.createChat().then(chat => {
      // this.chatbotUserChanged.emit(values[0]);
      this.chatCreated.emit(chat);
      newChat = chat;
    });
    return newChat;
  }

  async createChatAdmin() {
    const motiveKeys = ['NEU', 'SAN', 'EIN', 'STA', 'BES', 'AUT', 'SOZ', 'PRI', 'SEN', 'STR', 'SIC', 'REV', 'BEW', 'ESS', 'FAM', 'SIN'];
    const motives = motiveKeys.map(key => Number(this.adminCreateForm.get(key).value));
    let chatbotchatuser: ChatbotChatUser;
    if (motives.every(motive => typeof motive === 'number')) {
      chatbotchatuser = new ChatbotChatUser(
        this.currentUser.keycloakId,
        motives[0], motives[1], motives[2], motives[3], motives[4], motives[5], motives[6], motives[7], motives[8],
        motives[9], motives[10], motives[11], motives[12], motives[13], motives[14], motives[15]
      );
    }
    else {
      console.error('One or more values in "motives" are not numbers');
    }

    const updateUser = this.chatbotUserService.updateChatbotUser(chatbotchatuser);
    await firstValueFrom(updateUser);

    const chatCreate = new ChatbotChatCreate(this.currentUser.keycloakId);
    const addChat = this.chatbotChatService.addChat(chatCreate);
    const chat = await firstValueFrom(addChat);
    this.chatbotChat = chat;
    this.chatCreated.emit(chat);
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }
}
