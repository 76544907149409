<div class="chat-input-container">
  <ng-container *ngIf="errorOccurred">
    <div class="chat-message-error">
      <div class="error-icon-wrapper">
        <img src="assets/icons/chatbot/ChatErrorMessageIcon.svg"  alt="error icon"/>
      </div>
      <span class="error-message-wrapper">
        {{errorMessage}}
      </span>
    </div>
  </ng-container>

  <div class="chat-input-box">
    <div class="input">
      <!--suppress HtmlFormInputWithoutLabel -->
      <textarea autosize [maxRows]="10"
                [minRows]="1"
                (focusin)="inputFocused = true"
                (focusout)="inputFocused = false"
                [(ngModel)]="message"
                placeholder="{{__('inputPlaceholder')}}">
      </textarea>
    </div>

    <div class="actions">
        <div class="send" [class.active]="!isMessageEmpty() && !isChatbotActive()">
          <div class="img-wrapper">
            <img (click)="send()" alt="send message: inactive, please enter a message"
                 src="assets/icons/ID37_Chatbot_IconSend_Filled.svg"/>
          </div>
        </div>
    </div>
  </div>
  <div class="chat-input-disclaimer">
    <p class="first-disclaimer">{{ __('inputDisclaimer1') }}</p>
    <p [innerHTML]="__('inputDisclaimer2')"></p>
  </div>
</div>

<app-chatbot-chat-create-admin-form [form]="adminCreateForm" [(modalOpen)]="adminCreateFormOpen" (formSubmit)="newChatAdmin()"></app-chatbot-chat-create-admin-form>
