<form class="unzerUI form" id="payment-form" novalidate>

  <div class="field">
    <div class="unzerInput" id="card-element-id-number">
      <!-- Card number UI Element will be inserted here. -->
    </div>
  </div>

  <div class="two fields">
    <div class="field ten wide">
      <div class="unzerInput" id="card-element-id-expiry">
        <!-- Card expiry date UI Element will be inserted here. -->
      </div>
    </div>

    <div class="field six wide">
      <div class="unzerInput" id="card-element-id-cvc">
        <!-- Card CVC UI Element will be inserted here. -->
      </div>
    </div>
  </div>

  <div class="field">
    <!--suppress XmlDuplicatedId -->
    <button *ngIf="!ongoingSubmission"
            class="unzerUI primary button fluid"
            id="submit-button"
            type="submit">
      {{__('buy')}}
    </button>
    <!--suppress XmlDuplicatedId -->
    <button *ngIf="ongoingSubmission"
            class="unzerUI primary button fluid"
            disabled
            id="submit-button">
      {{__('buy')}}
    </button>
  </div>

</form>
