import {Routes} from '@angular/router';
import {Id37AuthGuard} from './id37-auth-guard.service';
import {MyAccountComponent} from '../account/my-account/my-account.component';
import {AdminComponent} from '../admin/admin.component';
import {DiscountcodesComponent} from '../admin/discountcodes/discountcodes.component';
import {MyCustomersComponent} from '../master/my-customers/my-customers.component';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {SupportComponent} from '../master/support/support.component';
import {AdminreportComponent} from '../admin/adminreport/adminreport.component';
import {ReportComponent} from '../report/report.component';
import {CollapsibleTestComponent} from '../collapsible/collapsible-test/collapsible-test.component';
import {ToolboxComponent} from '../master/toolbox/toolbox.component';
import {ImportComponent} from '../master/toolbox/import/import.component';
import {ComparerComponent} from '../master/toolbox/comparer/comparer.component';
import {ComparisonComponent} from '../master/toolbox/comparison/comparison.component';
import {TeamAnalysisComponent} from '../master/toolbox/teamanalysis/teamanalysis.component';
import {OrderComponent} from '../order/order/order.component';
import {OrderCompletedComponent} from '../order/order-completed/order-completed.component';
import {OrdersComponent} from '../order/orders/orders.component';
import {OrderProcessingComponent} from '../order/order-processing/order-processing.component';
import {OrderNotCompletedComponent} from '../order/order-not-completed/order-not-completed.component';
import {MyTestComponent} from '../test/my-test/my-test.component';
import {StartPageComponent} from '../test/my-test/start-page/start-page.component';
import {ItemPageComponent} from '../test/my-test/item-page/item-page.component';
import {ThankyouPageComponent} from '../test/my-test/thankyou-page/thankyou-page.component';
import {LogoutComponent} from '../user/logout/logout.component';
import {ServiceComponent} from '../admin/service/service.component';
import {FriendsComponent} from '../friend/friends.component';
import {FriendFeatureRoutesGuard} from './friend-feature-routes-guard';
import {TestResetComponent} from '../test/test-reset/test-reset.component';
import {MetricsComponent} from '../admin/metrics/metrics.component';
import {SelectTestComponent} from '../select/select-test/select-test.component';
import {DateTimeTestComponent} from '../datetime/datetime-test/datetime-test.component';
import {FriendCompareSiteComponent} from '../friend/friend-compare-site/friend-compare-site.component';
import {CircularRingTestComponent} from '../circular-ring/circular-ring-test/circular-ring-test.component';
import {KeyGraphicViewComponent} from '../graphics/key-graphic/key-graphic-view/key-graphic-view.component';
import {DistanceGraphicTestComponent} from '../distance-graphic/distance-graphic-test/distance-graphic-test.component';
import {PricingReportComponent} from '../admin/pricing-report/pricing-report.component';
import {MessagesComponent} from '../admin/messages/messages/messages.component';
import {ChatComponent} from '../chat/chat/chat.component';
import {ChatFeatureRoutesGuard} from './chat-feature-route-guard';
import {BillingDetailsComponent} from '../admin/billing-details/billing-details.component';
import {StatisticsComponent} from '../admin/statistics/statistics.component';
import {TextComponent} from '../text/text.component';
import {FriendshipGraphComponent} from '../admin/friendship-graph/friendship-graph.component';
import {ChartComponent} from '../chart/chart.component';
import {TeamComponent} from '../team/team.component';
import {StylesComponent} from '../styles/styles.component';
import {TestFeatureRouteGuard} from './test-feature-route-guard';
import {
  DiscountCodeUsagesReportComponent
} from '../admin/discountcodes/discount-code-usages-report/discount-code-usages-report.component';
import {
  PositionUserReportComponent
} from '../admin/position-user-report/position-user-report.component';
import {ChatbotDocumentsComponent} from '../admin/chatbot-documents/chatbot-documents.component';
import {ChatbotChatComponent} from '../chatbot/chat/chatbot-chat.component';
import {
  ChatbotDocumentsToolsComponent
} from '../master/toolbox/chatbot-documents-tools/chatbot-documents-tools.component';
import {ChatbotSystemPromptComponent} from '../admin/chatbot-system-prompt/chatbot-system-prompt.component';

export const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'report/:userId/:testId',
    component: ReportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/report',
    component: AdminreportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/messages',
    component: MessagesComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/pricing-report',
    component: PricingReportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/discountcode',
    component: DiscountcodesComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/discountcode-usages-export',
    component: DiscountCodeUsagesReportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/service',
    component: ServiceComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/statistics',
    component: StatisticsComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/metrics',
    component: MetricsComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/billing-details',
    component: BillingDetailsComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/friendship-graph',
    component: FriendshipGraphComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/chatbot-documents',
    component: ChatbotDocumentsComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'admin/chatbot-system-prompt',
    component: ChatbotSystemPromptComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'chatbot',
    component: ChatbotChatComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'admin/position-user-report',
    component: PositionUserReportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'friends',
    component: FriendsComponent,
    canActivate: [Id37AuthGuard, FriendFeatureRoutesGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'friends/friend-compare/:friendshipId',
    component: FriendCompareSiteComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [Id37AuthGuard, ChatFeatureRoutesGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'my-customers',
    component: MyCustomersComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: ['master', 'partner']
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'order',
    component: OrderComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'orders/:orderId',
    component: OrdersComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'orders/:orderId/process',
    component: OrderProcessingComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'orders/:orderId/completed',
    component: OrderCompletedComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'orders/:orderId/not-completed',
    component: OrderNotCompletedComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'my-test/:testId',
    component: MyTestComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'my-test/:testId/start',
    component: StartPageComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'my-test/:testId/items',
    component: ItemPageComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'my-test/:testId/thank-you',
    component: ThankyouPageComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'test-reset',
    component: TestResetComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'key-graphic/:testId',
    component: KeyGraphicViewComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'toolbox',
    component: ToolboxComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'master'
    }
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'master'
    }
  },
  {
    path: 'toolbox/import',
    component: ImportComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'master'
    }
  },
  {
    path: 'toolbox/compare',
    component: ComparisonComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'master'
    }
  },
  { // TODO: TO BE DELETED ?
    path: 'toolbox/teamanalysis',
    component: TeamAnalysisComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'master'
    }
  },
  {
    path: 'toolbox/comparer',
    component: ComparerComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'toolbox/chatbot-documents',
    component: ChatbotDocumentsToolsComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'chart',
    component: ChartComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'master'
    }
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'collapsible-test',
    component: CollapsibleTestComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'select-test',
    component: SelectTestComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'datetime-test',
    component: DateTimeTestComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'circular-ring-test',
    component: CircularRingTestComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'graphic',
    component: DistanceGraphicTestComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'text',
    component: TextComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'administrator'
    }
  },
  {
    path: 'teams',
    component: TeamComponent,
    canActivate: [Id37AuthGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: 'styles',
    component: StylesComponent,
    canActivate: [Id37AuthGuard, TestFeatureRouteGuard],
    data: {
      anyRoleRequired: 'client'
    }
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];
